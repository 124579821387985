<template>
  <a-card :bordered="false">
    <div>
      <div class="table-page-search-wrapper">
        <a-page-header
          style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
          title="合同资产详情"
          sub-title=""
          @back="() => $router.go(-1)"
        >
        </a-page-header>
      </div>
      <s-table
        ref="table"
        size="small"
        rowKey="rental_price"
        :columns="columns"
        :data="loadData"
      >
        <div slot="count_render" slot-scope="text" style="text-align: right; min-width: 90px; max-width: 160px">
          {{ text }}
        </div>
        <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 100px; max-width: 140px">
          {{ (text / 100).toFixed(6) }}
        </div>
        <div slot="long_money_render" slot-scope="text" style="text-align: right; min-width: 100px; max-width: 140px">
          {{ (text / 10000).toFixed(4) }}
        </div>
      </s-table>
    </div>
  </a-card>
</template>

<script>
import { STable } from '@/components'

import { Dictionaries } from '@/common/AllConstant'
import { rent_contract_properties } from '@/api/rent_contract'

export default {
  name: 'TableList',
  components: {
    STable
  },
  props: {
  },
  data () {
    return {
      property_bill_visible: false,
      rent_bill_visible: false,
      previewvisible: false,
      previewurl: '',
      dataSource: [],
      corporation_id: null,
      tip: '',
      corList: [],
      bookvisble: false,
      bookLoading: false,
      current_contract_id: '',
      billvisble: false,
      billLoading: false,
      billTime: {},
      content: '',
      previsible: false,
      loading: false,
      confirmLoading: false,
      confirmPropertyBillLoading: false,
      confirmRentBillLoading: false,
      confirmAttachmentLoading: false,
      preLoading: false,
      dictionaries: Dictionaries,
      visibleAttachment: false,
      attachmentListItemId: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '大类名称',
          dataIndex: 'zone_property_cell__zone_property__name',
          ellipsis: true
        },
        {
          title: '小类名称',
          dataIndex: 'zone_property_cell__name',
          ellipsis: true
        },
        {
          title: '面积（平方米）',
          dataIndex: 'used',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'count_render' }
        },
        {
          title: '租金',
          dataIndex: 'rental_price',
          align: 'center',
          scopedSlots: { customRender: 'money_render' },
          ellipsis: true
        },
        {
          title: '物业费',
          dataIndex: 'property_price',
          align: 'center',
          scopedSlots: { customRender: 'long_money_render' },
          ellipsis: true
        },
        {
          title: '类型',
          dataIndex: 'zone_property_cell__zone_property__property_type',
          customRender: (text) => this.$Dictionaries.property_type[text],
          ellipsis: true
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return rent_contract_properties(this.$route.params.id)
          .then(res => {
            this.loading = false
            const data = { entries: res.data }
            return data
          }).finally(() => {
            this.loading = false
          })
      }
    }
  },
  created () {
    console.log(this.$Dictionaries, ' 我是合同状态')
  },
  methods: {

  }
}
</script>
