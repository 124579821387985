import request from '@/utils/request'

const rent_contractApi = {
  rent_contract_list: '/rent_contract/',
  rent_contract_create: '/rent_contract/',
  rent_contract_update: '/rent_contract/',
  rent_contract_partial_update: '/rent_contract/',
  rent_contract_delete: '/rent_contract/',
  rent_contract_attachment_create: '/contract/annex_upload/',
  rent_contract_properties: '/rent_contract/contract_id/zone_property_cell/'
}

/**
 * 列表
 */
export function rent_contract_list (parameter) {
  return request({
    url: rent_contractApi.rent_contract_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function rent_contract_create (parameter) {
  return request({
    url: rent_contractApi.rent_contract_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function rent_contract_update (parameter, rent_contract_id) {
  return request({
    url: rent_contractApi.rent_contract_update + rent_contract_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 修改
 * @param
 * {
 *  "change_to": 0
 * }
 */
export function rent_contract_partial_update (parameter, rent_contract_id) {
  return request({
    url: rent_contractApi.rent_contract_partial_update + rent_contract_id + '/',
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 删除
 */
export function rent_contract_delete (parameter, rent_contract_id) {
  return request({
    url: rent_contractApi.rent_contract_delete + rent_contract_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function rent_contract_attachment_create (parameter) {
  return request({
    url: rent_contractApi.rent_contract_attachment_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 列表
 */
export function rent_contract_properties (id) {
  return request({
    url: rent_contractApi.rent_contract_properties.replace('contract_id', id),
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: { contract_id: id }
  })
}
